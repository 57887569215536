<template>
  <v-container fluid>
    <v-flex xs12 sm12 md12>
      <v-img
        lazy-src="../assets/images/blogimages/world.jpg"
        src="../assets/images/blogimages/world.jpg"
        width="1550"
        height="400"
        gradient="rgba(100,100,100,.3), rgba(100,100,100,.3)"
        style="overflow: hidden"
        class="mt-n4"
      >
        <v-overlay absolute>
          <v-card
            flat
            color="transparent"
            class="d-xs-flex d-sm-flex d-md-none"
          >
            <v-card-text
              class="white--text font-weight-regular text-center text-h3"
              >Transportation Management System: Benefits, Features</v-card-text
            >
          </v-card>
          <v-card
            flat
            color="transparent"
            class="d-none d-md-flex d-lg-flex d-xl-flex"
          >
            <v-card-text
              class="white--text font-weight-regular text-center text-h2"
              >Transportation Management System: Benefits, Features & Requirements</v-card-text
            >
          </v-card>
        </v-overlay>
      </v-img>
    </v-flex>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md8>
        <h4 class="text-right mt-3">
            Written By : Deepak Rishi (BulkVan)
        </h4>
        <h4 class="text-right font-weight-light">
            12th January, 2023 
        </h4>
        <!-- <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          Transportation
        </h3> -->
        <h4 class="text--disabled">Reading time: 15 minutes</h4>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          <b style="font-style: italic; margin-right: 3px">"Transportation"</b>
          is one of the main supply chain processes along with manufacturing and
          warehousing. The company typically spends a large part of the landed
          cost on transportation. There are a lot of activities involved - from
          delivery planning to carrier management to shipment tracking - that
          have to be handled properly.
        </p>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          Small businesses with few shipments can manage these tasks with the
          help of spreadsheets and phone calls. However, once your business
          grows, it becomes clear that Excel is not enough and you need
          something more robust to control your transportation processes. That's
          where special techniques come in handy.
        </p>
        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          What is a Transportation Management System (TMS)?
        </h3>

        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          A transportation management system or TMS is a class of software that
          helps in planning and executing the physical movement of goods. It can
          be used by all members of the supply chain, from manufacturers to
          distributors and third-party logistics providers (3PLs) – virtually
          any party that wishes to coordinate shipments. A typical scenario
          would include both inbound (Purchasing) and outbound (Shipping)
          orders, which would be evaluated by the TMS Planning module, offering
          the user various suggested routing solutions.
        </p>

        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold text-center"
        >
          Transport Management Process
        </h3>

        <v-img
          src="../assets/images/blogimages/planning.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />

        <h3 class="#311B92--text pl-2 mt-4 text-h5 font-weight-regular">
          Good transport management tools help in the following aspects of the
          process
        </h3>
        <v-list>
          <v-list-item-content>
            <v-list-item-title class="ml-4 font-weight-bold mb-2"
              ><span class="mr-1">1.</span>Planning</v-list-item-title
            >
            <v-list-item-title class="ml-4 font-weight-bold mb-2"
              ><span class="mr-1">2.</span>Mode Evaluation and
              Selection</v-list-item-title
            >
            <v-list-item-title class="ml-4 font-weight-bold mb-2"
              ><span class="mr-1">3.</span>Vendor Evaluation and
              Management</v-list-item-title
            >
            <v-list-item-title class="ml-4 font-weight-bold mb-2"
              ><span class="mr-1">4.</span>Track and trace
              capabilities</v-list-item-title
            >
            <v-list-item-title class="ml-4 font-weight-bold mb-2"
              ><span class="mr-1">5.</span>Commercial and
              contracts</v-list-item-title
            >
            <v-list-item-title class="ml-4 font-weight-bold mb-2"
              ><span class="mr-1">6.</span>Exception Handling
              Capabilities</v-list-item-title
            >
          </v-list-item-content>
        </v-list>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          The system should be able to have the multi-dimensional capabilities
          of recording data, integrating with the existing applications,
          interacting with external parties, reporting and analytical
          capabilities, and generating and evaluating alternatives. A good
          system will also be capable of optimization around the multiple
          alternatives to suggest the most efficient and least cost option.
        </p>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          Significant developments have happened in the overall ecosystem around
          IoT, telematics, geo coding, etc to increase the efficiency in the
          transportation processes. India has been slightly behind the curve on
          the adoption and utilization of these developments but is changing
          fast.
        </p>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          These solutions are evaluated by the user for reasonableness and
          passed along to the transport provider analysis module to select the
          best mode and least cost provider. Once the best provider is selected,
          the solution typically generates electronic load tendering and
          track/trace to execute the customized shipment by the selected carrier
          and subsequently to support freight and payment (settlement process).
        </p>
        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          Control Tower
        </h3>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          With control tower capability embedded in your TMS, all your
          transportation data lives in one place. Avoid the confusion of
          reporting out of multiple systems. Already have ERP or WMS systems
          that you love? Easily integrate them with your TMS to consolidate data
          for your entire enterprise.
        </p>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          A control tower does not just deliver a static report that captures a
          moment of supply chain activity it lets you SEE your shipments in real
          time on a visual, map-based interface. So, it is easy to see your
          problem areas, and where you have extra capacity. You can identify and
          remove supply chain friction.
        </p>
        <v-img
          src="../assets/images/blogimages/controltower.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          The Control Tower leverages both real-time operational data and
          advanced, predictive ETA data and dynamic rerouting insights to
          generate a holistic view of current and anticipated incidents. These
          features add up to a better, more informed, transparent approach to
          handling exceptions.
        </p>

        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          Transportation planning features
        </h3>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          The transportation process starts with creating a transport request
          with the quote and details about the shipment. Shippers tender loads
          to carriers who, in turn, have to plan the capacity and cargo
          distribution.
        </p>

        <p
          style="text-align: justify; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          <strong class="mr-2">Order Management:</strong>In a TMS, order
          information is accessed by all related parties from planners to
          drivers and customers. Here are the features that help users to view
          and manage transport requests.
        </p>
        <v-img
          src="../assets/images/blogimages/book.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />

        <p
          style="text-align: justify; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          <strong class="mr-2">Order Entry:</strong>Manual or automatic, order
          entering includes filling in all essential details like commodity or
          weight and setting up the current location and destination. A system
          often automatically generates a route, assigns transport and driver,
          and calculates ETA and this order automatically goes to its scheduled
          transport.
        </p>

        <v-img
          src="../assets/images/blogimages/form.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />

        <p
          style="text-align: justify; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          <strong class="mr-2">Order overview:</strong> Created orders with
          their routes and assigned transports are available to order planners
          and drivers on a dashboard that allows them to see their workload for
          the day. All documents are also automatically linked to their
          corresponding orders so the driver can keep them on their smartphone
          or tablet. As drivers update the status of orders in route, planners
          have an overview of all Scheduling changes and truck locations.
        </p>

        <p
          style="text-align: justify; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          <strong class="mr-2">Shipment Rate Management:</strong> One of the
          most important tools within TMS is the rating engine. A rate engine
          calculates the transportation charges for PTL, LTL, truckload, and
          intermodal shipping based on the basis of different-2 rules like base
          rates, discounts, and contract agreements. A System should be able to
          generate custom pricing rules to accommodate even the most complex
          tariffs and automatically send a price quote to the customer when he
          requested.
        </p>

        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          Routes Management
        </h3>

        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          Creation of the most efficient load distribution among the available
          resources and planning the execution process entails evaluation of
          millions of options simultaneously. There is no way one can achieve
          this through a manual process. A good TMS typically has AI/ML led
          algorithms to solve this problem in the most optimum manner. Depending
          on the size of the problem the system should have the following
          interventions :-
        </p>
        <v-img
          src="../assets/images/blogimages/route.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />
        <div>
          <ul class="text-h6 font-weight-regular">
            <li class="mb-2">
              Ability to have a reasonably good solution rather than aim for the
              most efficient solution citing the trade off between the time to
              generate the solution and marginal efficiency gained.
            </li>
            <li class="mb-2">
              Right places for manual interventions for incorporating the local
              variances and heuristics.
            </li>
            <li class="mb-2">
              Ability to capture historical data and learn from the process to
              provide incrementally better solutions.
            </li>
          </ul>
        </div>

        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          Load Planning
        </h3>

        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          Procurement of transport resources may happen in multiple forms for
          any organization. The utilization of fixed and variable resources to
          generate the right level of customer experience and costs is critical.
          In today's environment, the focus of companies on customer experience
          typically renders the dilution of the cost focus, but this makes the
          sustainability of the product untenable.
        </p>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          So it is critical to have a balanced approach toward the selection and
          allocation of the resource.
        </p>
        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          The ability to interact real time with the vendors for cost discovery
          and bidding during the asset selection process becomes critical in
          ensuring the asset availability and the right cost for the service.
        </p>

        <v-img
          src="../assets/images/blogimages/fllet.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />

        <p
          style="text-align: justify; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          <strong class="mr-2"> Fleet Management:</strong> If you operate your
          own fleet, a TMS will let you see and utilize your assets on a single
          platform. Even though all TMS providers didn’t have advanced fleet
          management features, you can assign drivers and equipment, plus manage
          dispatch and financial settlement for drivers. If you’re using Bulkvan
          TMS software, you can go as far as managing ETA, Distance calculation
          with multi-locations and you also can choose the best route for the
          delivery, keep track of vehicles, and many other activities. Also, You
          can Track the Stage Of the order As the Vehicle is assigned, Reached
          the WareHouse, is Dispatched, is In Transit, and Many more.
        </p>

        <v-img
          src="../assets/images/blogimages/bid.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />
        <p
          style="text-align: justify; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          <strong class="mr-2">Tracking Management:</strong> By integrating GPS
          tracking into a TMS, businesses can monitor the movement of goods in
          real time, from origin to final destination and on to the end
          consumer. This visibility enables more accurate shipment and progress
          estimates while reducing manual processes and unnecessary
          communication between customers and drivers. Now, These days you can
          also share the location with someone and the driver also can update
          the location with the help of a given link.
        </p>

        <v-img
          src="../assets/images/blogimages/track.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />

        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          Documents Management
        </h3>

        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          Transportation, especially in the settlement part, is very complex due
          to the heavy documentation process. However, there is certain progress
          toward automation. Now you can generate Digital Bills, Tax invoices,
          eway bills, POD, etc With TMS ERP. You'll be able to streamline your
          billing and invoicing activities, set up automatic payments, and
          improve claims management by sending emails, messages, and
          notification regarding’s Payments and other things.
        </p>
        <v-img
          src="../assets/images/blogimages/bill.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />
        <!-- <v-img src="../assets/images/blogimages/track.jpg"  style="overflow: hidden" width="90%" class="mx-auto mt-4" /> -->

        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          Commercials and Contract Management
        </h3>

        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          Contract management is the process of managing contract creation,
          execution, and analysis to maximize operational and financial
          performance at an organization, all while reducing financial risk.
          Organizations encounter an ever-increasing amount of pressure to
          reduce costs and improve business performance. Contract management
          proves to be a very time-consuming element of business, which
          facilitates the need for an effective and automated contract
          management system. But Now you can Make Digital contracts on the basis
          of business requirements which makes every decision very quick and
          simple.
        </p>

        <v-img
          src="../assets/images/blogimages/contract.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />

        <h3
          class="#311B92--text pl-2 mt-4 text-h5 font-weight-bold"
          style="border-left: 5px solid indigo !important"
        >
          Business Intelligence
        </h3>

        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          Business Intelligence is the practice of improving business results
          using data. The biggest advantages of BI over regular spreadsheets are
          real-time analytics and an array of custom reports that anyone from
          the transportation management team can generate for their needs.
        </p>

        <v-img
          src="../assets/images/blogimages/dashb.jpg"
          style="overflow: hidden"
          width="90%"
          class="mx-auto mt-4"
        />

        <p
          style="text-align: justify; text-indent: 25px; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular"
        >
          For Example, there are Differing kinds of reports available for you by
          which you have to Idea how you can improve your business like vehicle
          stoppage report, tracking report speed report, running reports monthly
          and daily order graphs you can compare your cost, calculate ETA and
          many More Intelligence Like.
        </p>
        <h4>Vendor Evaluation</h4>
        <h4>MIS Reports</h4>
        <h4>Price Reports</h4>
        <h4>Different Kinds of Alerts for Customers/Seller/Suppliers</h4>
        <h4>Exception reporting</h4>

        <p
          style="text-align: justify; line-height: 30px"
          class="mt-4 text-h6 font-weight-regular mb-8"
        >
          <strong class="mr-2">Conclusion:</strong> With shipping costs rising,
          many companies are looking for ways to make up those resources.
          Luckily, that’s precisely what TMS ERP does. TMS ERP is almost
          required these days to reduce costs for the business and the end
          customer. Simplification of supply chain processes across geographies,
          modes, and carriers. Automation of business operations for faster and
          more accurate billing and documentation. Improvement in visibility and
          security, especially in transit. With the help of
          <b>Bulkvan TMS ERP</b>, you’ll get all your required solutions in one
          place which makes your business very easy & simple and reduces your
          time and costs.
        </p>

        <v-card class="mb-4" >
            <v-row>
            <v-col cols="1" md="1" sm="1" style="background:#651FFF; overflow: hidden;">
                <v-icon class="ml-1 text-h3" dark>mdi-sticker-emoji</v-icon>
                <v-card-subtitle class="text-h6 mt-n2 white--text" >S A T</v-card-subtitle></v-col>
            <v-col cols="10" md="10" sm="10">
                <v-card-title class="mt-n6 ml-n2">Ramanujan Sharma</v-card-title>
                <v-card-subtitle>14th Jan, 2023</v-card-subtitle>
            <v-card-text>
                <p class="text-subtitle1">
                   I think in current supply chain system without a TMS in place, you may have a general gut feeling of who the cheapest carrier has been over the past year, or which carrier had the highest percentage of on-time shipments. However, without technology on your side, it’s hard to know for certain.
                </p>
            </v-card-text>
            </v-col>
           
        </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
  export default {
    components: { },
  }
</script>
