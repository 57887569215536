<template>
  <v-app>
    <v-app-bar app flat dense color="deep-purple accent-3" dark hide-on-scroll >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          width="100"
          style="cursor: pointer;"
          @click="gotoMain()"
          src="./assets/bvnNav.png"
          transition="scale-transition"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://trackme.bulkvan.com"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <template>
  <v-footer padless color="indigo" dark>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>BulkVan</strong>
    </v-col>
  </v-footer>
</template>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //ToDo
  }),
  methods:{
    gotoMain(){
      window.open("https://www.bulkvan.com", '_blank');
    }
  }
};
</script>
